<template>
  <!-- 加入供應商 -->
  <div id="createDate" v-if="step === 1" class="intro-y box p-10 mt-10">
    <div class="font-bold text-2xl xl:text-3xl my-5">建立供應商的相關條款</div>
    <div class="mb-20 px-0">
      <ul class="text-base text-gray-600 list-decimal pl-5 mt-5 mb-10">
        <li class="mb-2">政府核准設立之公司統編。</li>
        <li class="mb-2">公司登記名稱。</li>
        <li class="mb-2">公司登記地址。</li>
        <li class="mb-2">公司核准設立日期。</li>
        <li class="mb-2">公司資本額。</li>
      </ul>
    </div>
    <div class=" flex justify-between items-center">
      <div class="flex items-center mr-auto text-lg">
        <input
          id="agree"
          type="checkbox"
          class="input border mr-2"
          v-model="aggreeApply"
          value="true"
        />
        <label class="cursor-pointer select-none" for="agree"
          >我已閱讀，同意條款</label
        >
      </div>
      <div class=" flex items-center">
        <a
          href="javascript:;"
          @click="() => $emit('back')"
          class="button text-lg px-10 block text-theme-1 bg-transparent mr-2"
        >
          上一步
        </a>
        <a
          href="#"
          @click="step = 2"
          class="button text-lg px-10 block text-white bg-theme-1 shadow-md"
          :class="{ 'bg-theme-25': !aggreeApply }"
          :disabled="!aggreeApply"
        >
          下一步
        </a>
      </div>
    </div>
  </div>

  <div>
    <query-vendor
      v-if="step === 2"
      :data="applyData.company"
      :defaultStep="queryStep"
      @back="step = 1"
      @return="callback"
    ></query-vendor>
  </div>

  <!-- step3確認資料 -->
  <div v-if="step === 3" class="intro-y box p-10 mt-10">
    <VForm
      ref="joinForm"
      :validation-schema="joinValidationSchema"
      :initial-values="applyData.user"
      @submit="applySubmit"
    >
      <div class="font-bold text-2xl xl:text-3xl mt-5 mb-10">
        建立供應商企業資料
      </div>

      <div class="mb-20">
        <div class=" mb-5">
          <label class=" font-medium text-lg mb-2 block">公司名稱</label>
          <div class="text-base">{{ applyData.company.name }}</div>
        </div>
        <div class=" mb-5">
          <label class=" font-medium text-lg mb-2 block">申請人</label>
          <div class="text-base">{{ applyData.user.userName }}</div>
        </div>
        <div class="mb-5 flex items-center">
          <div class="w-2/3 sm:w-96">
            <label class="font-medium text-lg">業務聯絡信箱</label>
            <CfInputBox
              name="email"
              placeholder="asdfff@cisa.com"
              type="email"
              v-model="applyData.user.email"
              :disabled="false"
            ></CfInputBox>
          </div>
          <!-- <div class=" ">
              <button
                type="button"
                class="button items-center button--lg w-full lg:w-32 text-white bg-theme-1 align-top lg:mb-0 mt-6 ml-3"
              >
                修改
              </button>
            </div> -->
        </div>

        <div class=" mb-5">
          <label class=" font-medium text-lg mb-2">身份</label>
          <div class="text-base mb-3">
            <CfInputRadioBox
              id="principal"
              name="Identity"
              defaultValue="1"
              :modelValue="userIdentity"
              @update:modelValue="
                val => {
                  userIdentity = val;
                }
              "
            ></CfInputRadioBox>
            <label class="cursor-pointer select-none" for="principal">
              公司代表人、董監事、經理人
            </label>
          </div>
          <div class="text-base mb-3">
            <CfInputRadioBox
              id="assistant"
              name="Identity"
              defaultValue="2"
              :modelValue="userIdentity"
              @update:modelValue="
                val => {
                  userIdentity = val;
                }
              "
            ></CfInputRadioBox>
            <label class="cursor-pointer select-none" for="assistant">
              助理與特助
            </label>
          </div>
          <div class="text-base mb-3">
            <CfInputRadioBox
              id="sales"
              name="Identity"
              defaultValue="3"
              :modelValue="userIdentity"
              @update:modelValue="
                val => {
                  userIdentity = val;
                }
              "
            ></CfInputRadioBox>
            <label class="cursor-pointer select-none" for="sales">
              業務負責人
            </label>
          </div>
          <div class="text-base mb-3">
            <CfInputRadioBox
              id="other"
              name="Identity"
              defaultValue="9"
              :modelValue="userIdentity"
              @update:modelValue="
                val => {
                  userIdentity = val;
                }
              "
            ></CfInputRadioBox>
            <label class="cursor-pointer select-none" for="other">
              其他：
            </label>
            <div class="w-full">
              <CfInputBox
                name="contact_Title"
                placeholder="輸入職稱"
                :modelValue="applyData.user.contact_Title"
                @update:modelValue="
                  val => {
                    applyData.user.contact_Title = val;
                  }
                "
                :showMessage="true"
                type="text"
                :disabled="userIdentity !== '9'"
                class="sm:w-40"
              ></CfInputBox>
            </div>
          </div>
          <div class="text-base mb-3 fileUploadStyle">
            <div id="cardUpload" v-if="userIdentity !== '1'">
              <label class=" font-medium text-lg"
                >上傳名片<span class=" text-red-400">＊</span></label
              >
              <div class="w-full sm:w-96">
                <!-- <CfInputBox
                  name="user_card"
                  placeholder="請上傳公司名片影像(須包含姓名、公司名稱及email)"
                  type="file"
                  class=""
                  :modelValue="applyData.user.user_card"
                  @update:modelValue="
                    val => {
                      applyData.user.user_card = val;
                    }
                  "
                ></CfInputBox>
                 -->
                <div>
                  <div>
                    <small class=" text-gray-600 text-sm"
                      >請上傳公司名片影像(須包含姓名、公司名稱及email)</small
                    >
                  </div>
                  <file-uploader
                    :ref="el => (uploader = el)"
                    :headers="headers"
                    :data="imageData"
                    id="card-photo-uri"
                    style="min-width: 400px; max-width: 400px; min-height: 250px; max-height: 250px;"
                    class="mb-10"
                    mode="image"
                    :modelValue="applyData.user.user_card"
                    :action="uploadAction"
                    :autoUpload="true"
                    :limitedWidth="400"
                    :limitedHeight="250"
                    @update:modelValue="uploadResult"
                    @filter="uploaderFilter"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class=" flex justify-end items-center">
        <div class=" flex items-center">
          <a
            href="javascript:;"
            @click="step = 2"
            class="button items-center button--lg w-32 text-theme-1 bg-transparent align-top lg:mb-0"
          >
            上一步
          </a>

          <button
            type="submit"
            class="button items-center button--lg w-32 text-white bg-theme-1 align-top lg:mb-0 ml-3"
          >
            送出申請
          </button>
        </div>
      </div>
    </VForm>
  </div>

  <!-- step4 資料送出-->
  <div v-if="step === 4" class="intro-y box p-10 mt-10 h-96 content-center">
    <div class="item-center w-full h-full text-center">
      <div class="text-2xl">您的申請已完成</div>
      <div class="text-lg">
        平台正在為您開通功能，系統將於五秒後登出，請重新登入！
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import CloudFun, { defineComponent, ref, reactive } from "@cloudfun/core";
import { onMounted, watch } from "vue";
import CfInputBox from "@/cloudfun/components/CfInputBox.vue";
import CfInputRadioBox from "@/cloudfun/components/CfInputRadioBox.vue";
import { Form } from "vee-validate";
import * as Yup from "yup";
import QueryVendor from "./QueryVendor.vue";
import { applyVendorStore } from "@/store/apply-vendor";
import { storeToRefs } from "pinia";
import FileUploader from "@/cloudfun/components/FileUploader.vue";
import { VueUploadItem } from "vue-upload-component";
export default defineComponent({
  components: {
    VForm: Form,
    CfInputBox,
    CfInputRadioBox,
    QueryVendor,
    FileUploader
  },
  setup() {
    const formData = reactive({ code: "" });
    const aggreeApply = ref(false);
    const applyStore = applyVendorStore();
    const { step, applyData } = storeToRefs(applyStore);
    const errorMessage = ref("");
    const otherEmail = ref("false");
    const userIdentity = ref("1");
    const user = JSON.parse(sessionStorage.getItem("EmptyNext-user")!);
    const imageData = ref(null);
    const model = CloudFun.current?.model;
    const info = ref();

    const headers = {
      Authorization: `Bearer ${user?.token}`
    };
    watch(step, current => {
      if (errorMessage.value) errorMessage.value = "";
      if (formData.code) formData.code = "";
    });

    const validationSchema = Yup.object().shape({
      code: Yup.string().required("必要欄位")
    });

    const joinValidationSchema = Yup.object().shape({
      email: Yup.string()
        .email("Email 格式不正確")
        .required("必要欄位"),
      contact_Title: Yup.string().test(
        "contact_Title",
        "必要欄位",
        (value, schema) => {
          if (userIdentity.value === "9")
            return value != null && value.length > 0;
          return true;
        }
      )
    });

    const getInfo = () => {
      return model
        ?.dispatch("user/find")
        .then(response => {
          if (response.status) {
            return response.payload;
          } else {
            CloudFun.send("error", {
              subject: "讀取失敗",
              content: response.message
            });
          }
        })
        .catch(error => {
          CloudFun.send("error", {
            subject: "讀取失敗",
            content: error.message
          });
        });
    };

    onMounted(async () => {
      info.value = await getInfo();
    });

    return {
      formData,
      step,
      errorMessage,
      validationSchema,
      applyData,
      joinValidationSchema,
      otherEmail,
      aggreeApply,
      userIdentity,
      headers,
      imageData,
      info,
      uploadAction: `${process.env.VUE_APP_BACKEND_URL}/api/v1/Image`
    };
  },
  methods: {
    back() {
      this.step = this.step > 1 ? this.step - 1 : 1;
    },
    callback(data: any) {
      this.applyData.company = data;
      this.step += 1;
      console.log(
        "🚀 ~ file: InviteVendor.vue ~ line 164 ~ callBack ~  this.applyData.company",
        this.applyData.company
      );
    },
    async uploaderFilter(
      current: VueUploadItem,
      original: VueUploadItem,
      prevent: any
    ) {
      if (!current || !current.name) return prevent();
      if (!/\.(png|gif|jpg|jpeg)$/i.test(current.name)) {
        alert("未支援此種圖片格式");
        return prevent();
      }
    },
    uploadResult(value: any) {
      if (value?.archiveID) {
        this.applyData.user.archiveID = value?.archiveID;
      } else if (value === "delete") this.applyData.user.archiveID = "";
    },
    applySubmit() {
      console.log("applyData", this.applyData);
      // this.applyData.user.contact_Title =
      //   this.userIdentity === "1" ? "公司代表人、董監事、經理人" : "助理與特助";
      this.applyData.user.contact_Tel = " ";
      this.applyData.user.position = this.userIdentity === "1" ? 1 : 2;
      this.applyData.company.unit = "";

      switch (this.userIdentity) {
        case "1":
          this.applyData.user.contact_Title = "公司代表人、董監事、經理人";
          this.applyData.user.position = 1;
          break;
        case "2":
          this.applyData.user.contact_Title = "助理與特助";
          this.applyData.user.position = 2;
          break;
        case "3":
          this.applyData.user.contact_Title = "業務負責人";
          this.applyData.user.position = 2;
          break;
        default:
          this.applyData.user.position = 2;
          break;
      }

      const data = {
        ...this.applyData.user,
        ...this.applyData.company
      } as any;
      delete data.email;
      delete data.userName;
      console.log(
        "🚀 ~ file: CrateVendor.vue ~ line 381 ~ applySubmit ~ data",
        data
      );

      if (data) {
        this.$model.dispatch("vendor/apply", data).then(
          response => {
            if (response.status) {
              CloudFun.send("success", {
                subject: "申請送出",
                content: "成功"
              });
              this.step = 4;
              setTimeout(() => {
                localStorage.setItem("upgrade", this.info.account);
                sessionStorage.clear();
                this.$router.push("/login");
              }, 5000);
            } else {
              CloudFun.send("error", {
                subject: "申請失敗",
                content: response.message
              });
            }
          },
          reason => {
            CloudFun.send("error", {
              subject: "申請失敗",
              content: reason
            });
          }
        );
      }
    }
  }
});
</script>

<style scoped></style>
