<template>
  <!-- 等待審核中start -->
  <!-- <div class=" flex justify-center items-center my-40">
    <div class=" items-center">
      <div class=" text-3xl mb-10 font-bold">等待審核中</div>
      <div class=" text-base">我們正在為您審核供應商資料，審核通過會直接開通頁面；<br>您可以進一步設定公司資料。</div>
    </div>
  </div> -->

  <!-- 您的申請已經完成start -->
  <!-- <div class=" flex justify-center items-center my-40">
    <div class=" items-center">
      <div class=" text-3xl mb-10 font-bold">您的申請已經完成</div>
      <div class=" text-base">平台正在為您開通。</div>
    </div>
  </div> -->

  <!-- 選擇建立資料 -->
  <div id="chooseStep" v-if="!isApplying && type === ''" class=" m-10">
    <div class="sm:px-10">
      <!-- <div class="mb-12 text-base text-gray-600">
        本平台提供您申請供應商身份，享有以下服務：
        <ul class=" list-decimal pl-5 mt-5 mb-10">
          <li class="mb-2">建立公司完整資料，與平台會員交流。</li>
          <li class="mb-2">申請成為供應商成員，協助公司管理產品。</li>
          <li class="mb-2">上架公司產品資訊。</li>
          <li class="mb-2">
            於軟協數位展覽入選展出公司產品與公司介紹。開拓公司商機。
          </li>
        </ul>
      </div> -->

      <div class=" xl:grid grid-cols-5 gap-5">
        <div
          class="box col-span-2 flex flex-col justify-between mb-20 xl:mb-0 pb-10"
        >
          <div class="">
            <div
              class=" bg-theme-5 flex items-center justify-center p-10 mb-10"
            >
              <div class=" text-center">
                <h3 class=" text-2xl font-semibold">建立供應商企業資料</h3>
              </div>
            </div>
            <div class="mb-10 text-base text-gray-600 px-5">
              <div class=" mb-5">
                在本平台建立供應商企業資料，您可以使用：
              </div>
              <div class=" font-bold text-gray-700">
                供應商資料庫，一次登錄，多展通用
              </div>
              <div class=" mb-4">
                我們有模組化商展模式，僅需維護一筆產品資料，有效確保產品資訊正確及維護效率。
              </div>
              <div class=" font-bold text-gray-700">
                便利的商洽系統，洽談最即時，商機不漏接
              </div>
              <div class=" mb-4">
                平台服務包含站內即時通訊、商品留言洽詢以及便利的線上會議功能，有效擴大洽談效益。
              </div>
              <div class=" font-bold text-gray-700">
                多樣的參展管道，更好的商機拓展
              </div>
              <div class=" mb-4">
                我們有多樣的國內外公、私營拓銷機會與資源，讓您的企業在業務開拓的路途上能借力使力，效益最大化。
              </div>
            </div>
          </div>
          <div class=" flex justify-center">
            <div class="">
              <!-- <a
                href="javascript:"
                class="button text-lg px-10 block text-white bg-theme-1 shadow-md"
                :class="{ 'bg-theme-25': true }"
                :disabled="true"
              >
                建立資料
              </a> -->

              <a
                href="javascript:"
                @click="goto('create')"
                class="button text-lg px-10 block text-white bg-theme-1 shadow-md"
                :disabled="true"
              >
                建立資料
              </a>
            </div>
          </div>
        </div>

        <div class="box col-span-3 pb-10">
          <div class=" lg:grid grid-cols-2 h-full">
            <div class=" col-span-2">
              <div
                class=" bg-theme-5 flex items-center justify-center p-10 mb-10"
              >
                <div class=" text-center">
                  <h3 class=" text-2xl font-semibold">加入供應商</h3>
                </div>
              </div>
            </div>
            <div
              class="flex flex-col justify-between px-5 border-b mb-10 pb-10 lg:pb-0 lg:mb-0 lg:border-b-0 lg:border-r border-gray-300"
            >
              <div class="">
                <div class=" text-lg mb-6 font-medium">
                  一般申請
                </div>
                <div class=" mb-10 text-base text-gray-600">
                  向您的公司提出加入申請，以獲得上架與管理公司產品的權限。
                </div>
              </div>
              <div class=" flex justify-center">
                <div class="">
                  <a
                    href="javascript:;"
                    class="button text-lg px-10 block text-white bg-theme-1 shadow-md"
                    :class="{ 'bg-theme-25': true }"
                    :disabled="true"
                  >
                    開始申請
                  </a>
                  <!-- <a
                      href="javascript:;"
                      @click="goto('invite')"
                      class="button text-lg px-10 block text-white bg-theme-1 shadow-md"
                    >
                      開始申請
                    </a> -->
                </div>
              </div>
            </div>
            <div class="px-5">
              <VForm
                :validation-schema="validationSchema"
                :initial-values="formData"
                @submit="submit"
                class="h-full"
              >
                <div class="flex flex-col justify-between ">
                  <div class="">
                    <div class=" text-lg mb-6 font-medium">
                      輸入邀請碼
                    </div>
                    <div class=" text-base text-gray-600 mb-8">
                      如果您已經收到公司寄給您的邀請碼，請填入邀請碼以完成供應商身份轉換。
                    </div>
                    <div class="mb-24 w-full">
                      <CfInputBoxHorizontal
                        label=""
                        name="code"
                        placeholder="輸入邀請碼"
                        type="text"
                        :isRequired="true"
                        :modelValue="formData.code"
                        @update:modelValue="
                          val => {
                            formData.code = val.trim();
                          }
                        "
                      ></CfInputBoxHorizontal>
                      <div class="text-base text-red-500 justify-center">
                        {{ errorMessage }}
                      </div>
                    </div>
                  </div>

                  <div class=" flex justify-center">
                    <div class="">
                      <button
                        type="submit"
                        class="button text-lg px-10 block text-white bg-theme-1 shadow-md"
                      >
                        送出邀請碼
                      </button>
                    </div>
                  </div>
                </div>
              </VForm>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- 建立供應商企業資料 -->
  <div>
    <invite-vendor
      v-if="!isApplying && type === 'invite'"
      @back="goto('')"
    ></invite-vendor>
  </div>
  <div>
    <create-vendor
      v-if="!isApplying && type === 'create'"
      @back="goto('')"
    ></create-vendor>
  </div>

  <div v-if="isApplying" class="intro-y box p-10 mt-10 h-96 content-center">
    <div class="item-center w-full h-full text-center">
      <div class="text-2xl">申請中</div>
      <div class="text-lg">等待審核通過後，將於您下次登入時開通本頁面</div>
    </div>
  </div>

  <div id="success-modal" class="modal">
    <div class="modal__content">
      <div class="p-5 text-center">
        <CheckCircleIcon class="w-16 h-16 text-theme-9 mx-auto mt-3" />
        <div class="text-3xl mt-5">供應商身份轉換完成</div>
        <div class="text-gray-600 mt-2">
          邀請碼輸入成功
        </div>
      </div>
      <div class="px-5 pb-8 text-center">
        <button
          type="button"
          class="button w-24 bg-theme-1 text-white"
          @click="gotoDashboard"
        >
          確定
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
/* eslint-disable */

import { ref, reactive, onMounted, provide, computed, watch } from "vue";
import CloudFun, { defineComponent } from "@cloudfun/core";
import CfInputBoxHorizontal from "@/cloudfun/components/CfInputBoxHorizontal.vue";
import CfInputBox from "@/cloudfun/components/CfInputBox.vue";
import InviteVendor from "./form/InviteVendor.vue";
import CreateVendor from "./form/CrateVendor.vue";
import { Form } from "vee-validate";
import * as Yup from "yup";
import { applyVendorStore } from "@/store/apply-vendor";
import { storeToRefs } from "pinia";
export default defineComponent({
  components: {
    CfInputBox,
    CfInputBoxHorizontal,
    VForm: Form,
    InviteVendor,
    CreateVendor
  },
  setup() {
    const applyStore = applyVendorStore();
    const { step, type, applyData } = storeToRefs(applyStore);
    const formData = reactive({ code: "" });
    const aggreeApply = ref(false);
    const model = CloudFun.current?.model;
    const isApplying = ref(false);
    const info = ref({} as any);
    const validationSchema = Yup.object().shape({
      code: Yup.string().required("必要欄位")
    });
    const errorMessage = ref("");

    watch(step, current => {
      console.log("🚀 ~ file: Application.vue ~ line 367 ~ setup ~ step", step);
      if (errorMessage.value) errorMessage.value = "";
      if (formData.code) formData.code = "";
    });

    const loadUserData = async () => {
      const userData = model?.dispatch("user/find");
      return userData;
    };

    const getStatus = async () => {
      const status = model?.dispatch("vendor/status");
      return status;
    };

    const getInfo = () => {
      return model
        ?.dispatch("user/find")
        .then(response => {
          if (response.status) {
            return response.payload;
          } else {
            CloudFun.send("error", {
              subject: "讀取失敗",
              content: response.message
            });
          }
        })
        .catch(error => {
          CloudFun.send("error", {
            subject: "讀取失敗",
            content: error.message
          });
        });
    };

    onMounted(async () => {
      info.value = await getInfo();
      applyStore.reset();
      const status = await getStatus();
      isApplying.value = status.payload.isApplying
        ? status.payload.isApplying
        : false;

      if (!isApplying.value) {
        const data = await loadUserData();
        applyData.value.user.email = data.payload.email;
        applyData.value.user.userName = data.payload.name;
        applyData.value.user.contact_Email = data.payload.email;
      }
    });

    return {
      validationSchema,
      formData,
      errorMessage,
      step,
      aggreeApply,
      type,
      applyData,
      applyStore,
      isApplying,
      info
    };
  },
  methods: {
    back() {
      this.step = this.step > 1 ? this.step - 1 : 1;
    },
    submit(data: any) {
      console.log("submit", data);
      this.errorMessage = "";
      this.$model.dispatch("vendor/invite", data).then(
        response => {
          console.log("invite response", response);
          if (response.status) {
            cash("#success-modal").modal("show");
          } else {
            this.errorMessage = response.message;
          }
        },
        reason => {
          console.log("invite reason", reason);
        }
      );
    },
    goto(type: string) {
      console.log("🚀 ~ file: Application.vue ~ line 339 ~ goto ~ type", type);

      this.type = type;
    },
    gotoDashboard() {
      cash("#success-modal").modal("hide");
      localStorage.setItem("upgrade", this.info.account);
      sessionStorage.clear();
      this.$router.replace("/login");

      // CloudFun.send("reloadAuth", "");
      // this.$router.replace("/dashboard");
    },
    goBack() {
      console.log("goback");
      this.type = "";
    }
  }
});
</script>
