
import CloudFun, { defineComponent, ref, reactive } from "@cloudfun/core";
import { onMounted, watch } from "vue";
import CfInputBox from "@/cloudfun/components/CfInputBox.vue";
import CfInputRadioBox from "@/cloudfun/components/CfInputRadioBox.vue";
import { Form } from "vee-validate";
import * as Yup from "yup";
import QueryVendor from "./QueryVendor.vue";
import { applyVendorStore } from "@/store/apply-vendor";
import { storeToRefs } from "pinia";
import FileUploader from "@/cloudfun/components/FileUploader.vue";
import { VueUploadItem } from "vue-upload-component";
export default defineComponent({
  components: {
    VForm: Form,
    CfInputBox,
    CfInputRadioBox,
    QueryVendor,
    FileUploader
  },
  setup() {
    const formData = reactive({ code: "" });
    const aggreeApply = ref(false);
    const applyStore = applyVendorStore();
    const { step, applyData } = storeToRefs(applyStore);
    const errorMessage = ref("");
    const otherEmail = ref("false");
    const userIdentity = ref("1");
    const user = JSON.parse(sessionStorage.getItem("EmptyNext-user")!);
    const imageData = ref(null);
    const model = CloudFun.current?.model;
    const info = ref();

    const headers = {
      Authorization: `Bearer ${user?.token}`
    };
    watch(step, current => {
      if (errorMessage.value) errorMessage.value = "";
      if (formData.code) formData.code = "";
    });

    const validationSchema = Yup.object().shape({
      code: Yup.string().required("必要欄位")
    });

    const joinValidationSchema = Yup.object().shape({
      email: Yup.string()
        .email("Email 格式不正確")
        .required("必要欄位"),
      contact_Title: Yup.string().test(
        "contact_Title",
        "必要欄位",
        (value, schema) => {
          if (userIdentity.value === "9")
            return value != null && value.length > 0;
          return true;
        }
      )
    });

    const getInfo = () => {
      return model
        ?.dispatch("user/find")
        .then(response => {
          if (response.status) {
            return response.payload;
          } else {
            CloudFun.send("error", {
              subject: "讀取失敗",
              content: response.message
            });
          }
        })
        .catch(error => {
          CloudFun.send("error", {
            subject: "讀取失敗",
            content: error.message
          });
        });
    };

    onMounted(async () => {
      info.value = await getInfo();
    });

    return {
      formData,
      step,
      errorMessage,
      validationSchema,
      applyData,
      joinValidationSchema,
      otherEmail,
      aggreeApply,
      userIdentity,
      headers,
      imageData,
      info,
      uploadAction: `${process.env.VUE_APP_BACKEND_URL}/api/v1/Image`
    };
  },
  methods: {
    back() {
      this.step = this.step > 1 ? this.step - 1 : 1;
    },
    callback(data: any) {
      this.applyData.company = data;
      this.step += 1;
      console.log(
        "🚀 ~ file: InviteVendor.vue ~ line 164 ~ callBack ~  this.applyData.company",
        this.applyData.company
      );
    },
    async uploaderFilter(
      current: VueUploadItem,
      original: VueUploadItem,
      prevent: any
    ) {
      if (!current || !current.name) return prevent();
      if (!/\.(png|gif|jpg|jpeg)$/i.test(current.name)) {
        alert("未支援此種圖片格式");
        return prevent();
      }
    },
    uploadResult(value: any) {
      if (value?.archiveID) {
        this.applyData.user.archiveID = value?.archiveID;
      } else if (value === "delete") this.applyData.user.archiveID = "";
    },
    applySubmit() {
      console.log("applyData", this.applyData);
      // this.applyData.user.contact_Title =
      //   this.userIdentity === "1" ? "公司代表人、董監事、經理人" : "助理與特助";
      this.applyData.user.contact_Tel = " ";
      this.applyData.user.position = this.userIdentity === "1" ? 1 : 2;
      this.applyData.company.unit = "";

      switch (this.userIdentity) {
        case "1":
          this.applyData.user.contact_Title = "公司代表人、董監事、經理人";
          this.applyData.user.position = 1;
          break;
        case "2":
          this.applyData.user.contact_Title = "助理與特助";
          this.applyData.user.position = 2;
          break;
        case "3":
          this.applyData.user.contact_Title = "業務負責人";
          this.applyData.user.position = 2;
          break;
        default:
          this.applyData.user.position = 2;
          break;
      }

      const data = {
        ...this.applyData.user,
        ...this.applyData.company
      } as any;
      delete data.email;
      delete data.userName;
      console.log(
        "🚀 ~ file: CrateVendor.vue ~ line 381 ~ applySubmit ~ data",
        data
      );

      if (data) {
        this.$model.dispatch("vendor/apply", data).then(
          response => {
            if (response.status) {
              CloudFun.send("success", {
                subject: "申請送出",
                content: "成功"
              });
              this.step = 4;
              setTimeout(() => {
                localStorage.setItem("upgrade", this.info.account);
                sessionStorage.clear();
                this.$router.push("/login");
              }, 5000);
            } else {
              CloudFun.send("error", {
                subject: "申請失敗",
                content: response.message
              });
            }
          },
          reason => {
            CloudFun.send("error", {
              subject: "申請失敗",
              content: reason
            });
          }
        );
      }
    }
  }
});
