<template>
  <div
    v-if="!status.isApplying && !status.isVendor"
    class="intro-y box p-10 mt-10"
  >
    <div class="font-bold text-2xl xl:text-3xl mt-5 mb-10">
      建立供應商企業資料
    </div>
    <div class="mb-10">
      <div class=" flex items-center">
        <div class="w-full sm:w-96">
          <label class="font-medium text-lg"
            >公司統編<span class=" text-red-400">＊</span></label
          >
          <CfInputBox
            name="taxId"
            placeholder="公司統編"
            maxLength="8"
            :modelValue="applyModal.formData.taxID"
            :disabled="true"
            @update:modelValue="
              val => {
                formData.taxId = val;
              }
            "
            :showMessage="true"
            type="text"
          ></CfInputBox>
        </div>
        <div
          class="text-base text-red-500 items-center w-full align-top lg:mb-0 mt-6 ml-3"
        >
          <small>查無資料，於下方欄位填寫稅籍資料</small>
        </div>
      </div>
    </div>

    <v-form
      v-if="applyModal.step == 1"
      :validation-schema="applyValidationSchema"
      @submit="applyModal.toStep2"
    >
      <!-- BEGIN: HTML Table Data -->
      <div>
        <div class=" lg:grid grid-cols-2 gap-5">
          <div class="">
            <div class="w-full sm:w-98 mb-8">
              <label class="font-medium text-lg"
                >公司名稱<span class=" text-red-400">＊</span></label
              >
              <CfInputBox
                name="name"
                placeholder="公司名稱"
                type="text"
                :isRequired="true"
                :modelValue="applyModal.formData.name"
                @update:modelValue="
                  val => {
                    applyModal.formData.name = val;
                  }
                "
              ></CfInputBox>
            </div>
            <div class="w-full sm:w-98 mb-8">
              <label class="font-medium text-lg"
                >核准設立日期<span class=" text-red-400">＊</span></label
              >
              <CfInputBox
                name="setup_Date"
                placeholder="民國年月日(ex.1110101)"
                type="text"
                :isRequired="true"
                :modelValue="applyModal.formData.setup_Date"
                @update:modelValue="
                  val => {
                    applyModal.formData.setup_Date = val;
                  }
                "
              ></CfInputBox>
            </div>
            <!-- <div class="w-full sm:w-98 mb-8">
                <label class="font-medium text-lg"
                  >資本額<span class=" text-red-400">＊</span></label
                >
                <CfInputBox
                  name="capital_Stock_Amount"
                  placeholder="資本額"
                  type="text"
                  :isRequired="true"
                  :modelValue="applyModal.formData.capital_Stock_Amount"
                  @update:modelValue="
                    val => {
                      applyModal.formData.capital_Stock_Amount = val;
                    }
                  "
                ></CfInputBox>
              </div>-->
          </div>
          <div class="">
            <div class="w-full sm:w-98  mb-8">
              <label class="font-medium text-lg">公司英文名稱</label>
              <CfInputBox
                name="name_Eng"
                placeholder="公司英文名稱"
                type="text"
                :isRequired="true"
                :modelValue="applyModal.formData.name_Eng"
                @update:modelValue="
                  val => {
                    applyModal.formData.name_Eng = val;
                  }
                "
              ></CfInputBox>
            </div>
            <div class="w-full sm:w-98  mb-8">
              <label class="font-medium text-lg">實收資本額</label>
              <CfInputBox
                name="paid_In_Capital_Amount"
                placeholder="實收資本額"
                type="text"
                :isRequired="true"
                :modelValue="applyModal.formData.capital_Stock_Amount"
                @update:modelValue="
                  val => {
                    applyModal.formData.capital_Stock_Amount = val;
                  }
                "
              ></CfInputBox>
            </div>
          </div>
        </div>
        <label class="font-medium text-lg mb-2"
          >公司登記地址<span class=" text-red-400">＊</span></label
        >
        <div class=" lg:grid grid-cols-3">
          <div class="w-full sm:w-96  mb-8">
            <label class="font-medium text-lg">縣市</label>
            <CfSelectBox
              name="city"
              placeholder="縣市"
              type="select"
              :modelValue="applyModal.formData.city"
              :items="city"
              @update:modelValue="
                val => {
                  applyModal.formData.city = val;
                  changeCity(val);
                }
              "
            ></CfSelectBox>
          </div>
          <div class="w-full sm:w-96  mb-8">
            <label class="font-medium text-lg">行政區</label>
            <CfSelectBox
              name="district"
              placeholder="行政區"
              type="select"
              :modelValue="applyModal.formData.district"
              :items="districts"
              @update:modelValue="
                val => {
                  applyModal.formData.district = val;
                }
              "
            ></CfSelectBox>
          </div>
          <div class="w-full sm:w-98  mb-8">
            <label class="font-medium text-lg">地址</label>
            <CfInputBox
              name="address"
              placeholder="公司登記地址"
              type="text"
              :isRequired="true"
              :modelValue="applyModal.formData.address"
              @update:modelValue="
                val => {
                  applyModal.formData.address = val;
                }
              "
            ></CfInputBox>
          </div>
        </div>
        <div class=" lg:grid grid-cols-1">
          <!-- <div class="w-full sm:w-98  mb-8">
                <label class="font-medium text-lg"
                  >公司登記地址<span class=" text-red-400">＊</span></label
                >
                <CfInputBox
                  name="address"
                  placeholder="公司登記地址"
                  type="text"
                  :isRequired="true"
                  :modelValue="applyModal.formData.address"
                  @update:modelValue="
                    val => {
                      applyModal.formData.address = val;
                    }
                  "
                ></CfInputBox>
              </div> -->
          <div class="w-full sm:w-98 mb-8">
            <label class="font-medium text-lg">公司登記英文地址</label>
            <CfInputBox
              name="address_Eng"
              placeholder="公司登記英文地址"
              type="text"
              :isRequired="true"
              :modelValue="applyModal.formData.address_Eng"
              @update:modelValue="
                val => {
                  applyModal.formData.address_Eng = val;
                }
              "
            ></CfInputBox>
          </div>
        </div>

        <!-- <div class="intro-y flex flex-col sm:flex-row items-center mt-8">        
            <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
              <button
                type="submit"
                class="button text-white bg-theme-1 shadow-md mr-2 inline-flex items-center"
              >
                <CheckIcon class="mr-2 w-4 h-4" />
                送出申請
              </button>
            </div>
          </div> -->

        <div class=" flex justify-end items-center">
          <div class=" flex items-center">
            <a
              href="javascript:;"
              @click="() => $emit('applyBack')"
              class="button text-lg px-10 block text-theme-1 bg-transparent mr-2"
            >
              回上一步
            </a>
            <button
              type="submit"
              class="button text-lg px-10 block text-white shadow-md bg-theme-1 mr-2"
            >
              下一步
            </button>
          </div>
        </div>
      </div>
    </v-form>

    <v-form
      v-if="applyModal.step == 2"
      :validation-schema="joinValidationSchema"
      @submit="applyModal.sendApply"
    >
      <!-- BEGIN: HTML Table Data -->
      <div class="font-bold text-2xl xl:text-3xl mt-5 mb-10">
        建立供應商企業資料
      </div>

      <div class="mb-20">
        <div class=" mb-5">
          <label class=" font-medium text-lg mb-2 block">公司名稱</label>
          <div class="text-base">{{ applyModal.formData.name }}</div>
        </div>
        <div class=" mb-5">
          <label class=" font-medium text-lg mb-2 block">申請人</label>
          <div class="text-base">{{ applyData.user.userName }}</div>
        </div>
        <div class="mb-5 flex items-center">
          <div class="w-2/3 sm:w-96">
            <label class="font-medium text-lg">業務聯絡信箱</label>
            <CfInputBox
              name="contact_Email"
              placeholder="asdfff@cisa.com"
              type="email"
              v-model="applyData.user.Contact_Email"
              :disabled="false"
            ></CfInputBox>
          </div>
          <!-- <div class=" ">
              <button
                type="button"
                class="button items-center button--lg w-full lg:w-32 text-white bg-theme-1 align-top lg:mb-0 mt-6 ml-3"
              >
                修改
              </button>
            </div> -->
        </div>

        <div class=" mb-5">
          <label class=" font-medium text-lg mb-2">身份</label>
          <div class="text-base mb-3">
            <CfInputRadioBox
              id="principal"
              name="Identity"
              defaultValue="1"
              :modelValue="userIdentity"
              @update:modelValue="
                val => {
                  userIdentity = val;
                }
              "
            ></CfInputRadioBox>
            <label class="cursor-pointer select-none" for="principal">
              公司代表人、董監事、經理人
            </label>
          </div>
          <div class="text-base mb-3">
            <CfInputRadioBox
              id="assistant"
              name="Identity"
              defaultValue="2"
              :modelValue="userIdentity"
              @update:modelValue="
                val => {
                  userIdentity = val;
                }
              "
            ></CfInputRadioBox>
            <label class="cursor-pointer select-none" for="assistant">
              助理與特助
            </label>
          </div>
          <div class="text-base mb-3">
            <CfInputRadioBox
              id="sales"
              name="Identity"
              defaultValue="3"
              :modelValue="userIdentity"
              @update:modelValue="
                val => {
                  userIdentity = val;
                }
              "
            ></CfInputRadioBox>
            <label class="cursor-pointer select-none" for="sales">
              業務負責人
            </label>
          </div>
          <div class="text-base mb-3">
            <CfInputRadioBox
              id="other"
              name="Identity"
              defaultValue="9"
              :modelValue="userIdentity"
              @update:modelValue="
                val => {
                  userIdentity = val;
                }
              "
            ></CfInputRadioBox>
            <label class="cursor-pointer select-none" for="other">
              其他：
            </label>
            <div class="w-full">
              <CfInputBox
                name="contact_Title"
                placeholder="輸入職稱"
                :modelValue="applyData.user.contact_Title"
                @update:modelValue="
                  val => {
                    applyData.user.contact_Title = val;
                  }
                "
                :showMessage="true"
                type="text"
                :disabled="userIdentity !== '9'"
                class="sm:w-40"
              ></CfInputBox>
            </div>
          </div>
          <div class="text-base mb-3 fileUploadStyle">
            <div id="cardUpload" v-if="userIdentity !== '1'">
              <label class=" font-medium text-lg"
                >上傳名片<span class=" text-red-400">＊</span></label
              >
              <div class="w-full sm:w-96">
                <!-- <CfInputBox
                  name="user_card"
                  placeholder="請上傳公司名片影像(須包含姓名、公司名稱及email)"
                  type="file"
                  class=""
                  :modelValue="applyData.user.user_card"
                  @update:modelValue="
                    val => {
                      applyData.user.user_card = val;
                    }
                  "
                ></CfInputBox>
                 -->
                <div>
                  <div>
                    <small class=" text-gray-600 text-sm"
                      >請上傳公司名片影像(須包含姓名、公司名稱及email)</small
                    >
                  </div>
                  <file-uploader
                    :ref="el => (uploader = el)"
                    :headers="headers"
                    :data="imageData"
                    id="card-photo-uri"
                    style="min-width: 400px; max-width: 400px; min-height: 250px; max-height: 250px;"
                    class="mb-10"
                    mode="image"
                    :modelValue="applyData.user.user_card"
                    :action="uploadAction"
                    :autoUpload="true"
                    :limitedWidth="400"
                    :limitedHeight="250"
                    @update:modelValue="uploadResult"
                    @filter="uploaderFilter"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class=" flex justify-end items-center">
        <div class=" flex items-center">
          <a
            href="javascript:;"
            @click="() => (applyModal.step = 1)"
            class="button items-center button--lg w-32 text-theme-1 bg-transparent align-top lg:mb-0"
          >
            上一步
          </a>

          <button
            type="submit"
            class="button items-center button--lg w-32 text-white bg-theme-1 align-top lg:mb-0 ml-3"
          >
            送出申請
          </button>
        </div>
      </div>
    </v-form>
  </div>
  <div v-else-if="status.isApplying">
    <div class="intro-y box p-10 mt-10">
      <div class="item-center w-full h-full text-center">
        <div class="text-2xl">等待審核中</div>
        <div class="text-lg">
          您的申請正在審核中，待負責人完成審核，平台將於下次登入時開通功能
        </div>
      </div>
      <!-- <div class=" text-lg text-gray-800 mb-2">申請狀態：等待審核中，我們正在為您審核供應商資料；審核通過會開通頁面；您可進一步設定公司資料。</div> -->
    </div>
  </div>
</template>

<script lang="ts">
/* eslint-disable */

import { ref, reactive, onMounted, provide, computed, watch } from "vue";
import CloudFun, { defineComponent } from "@cloudfun/core";
import ToUser from "@/components/user-modal/Main.vue";
import router from "@/router";
import CompanyEdit from "@/views/company-edit/Main.vue";
import CompanyApply from "@/views/company-apply/Main.vue";
import CfInputBox from "@/cloudfun/components/CfInputBoxHorizontal.vue";
import * as Yup from "yup";
import { Form } from "vee-validate";
import { cityList, districtList } from "@/libs/city";
import CfSelectBox from "@/cloudfun/components/CfSelectBox.vue";
import FileUploader from "@/cloudfun/components/FileUploader.vue";
import { VueUploadItem } from "vue-upload-component";
import CfInputRadioBox from "@/cloudfun/components/CfInputRadioBox.vue";
export default defineComponent({
  components: {
    ToUser,
    CompanyEdit,
    CompanyApply,
    VForm: Form,
    CfInputBox,
    CfSelectBox,
    FileUploader,
    CfInputRadioBox
  },
  props: {
    taxID: String
  },
  setup(props, { emit }) {
    const model = CloudFun.current?.model;
    const info = ref<any>({});
    const vendor = ref<any>({});
    const districts = ref<{ text: string; value: string }[]>([]);
    const city = ref<{ text: string; value: string }[]>([]);
    const applyData = reactive({ user: {} as any });
    const userIdentity = ref("1");
    const getStatus = () => {
      return model
        ?.dispatch("vendor/status")
        .then(response => {
          if (response.status) {
            return response.payload;
          } else {
            CloudFun.send("error", {
              subject: "讀取失敗",
              content: response.message
            });
          }
        })
        .catch(error => {
          CloudFun.send("error", {
            subject: "讀取失敗",
            content: error.message
          });
        });
    };

    const getInfo = () => {
      return model
        ?.dispatch("user/find")
        .then(response => {
          if (response.status) {
            return response.payload;
          } else {
            CloudFun.send("error", {
              subject: "讀取失敗",
              content: response.message
            });
          }
        })
        .catch(error => {
          CloudFun.send("error", {
            subject: "讀取失敗",
            content: error.message
          });
        });
    };

    const getAuth = () => {
      return model
        ?.dispatch("user/auth")
        .then(response => {
          if (response.status) {
            return response.payload;
          } else {
            CloudFun.send("error", {
              subject: "讀取失敗",
              content: response.message
            });
          }
        })
        .catch(error => {
          CloudFun.send("error", {
            subject: "讀取失敗",
            content: error.message
          });
        });
    };

    const auth = ref<any>({});
    const status = ref<any>({});

    onMounted(async () => {
      applyModal.formData.taxID = props.taxID;
      auth.value = await getAuth();
      status.value = await getStatus();
      info.value = await getInfo();
      city.value = cityList();
      console.log(city.value);
    });

    const applyModal = reactive({
      step: 1,
      formData: <any>{
        name_Eng: "",
        paid_In_Capital_Amount: "0",
        capital_Stock_Amount: "",
        address_Eng: "",
        unit: ""
      },
      async sendApply() {
        applyData.user.contact_Tel = " ";
        // applyData.user.position = userIdentity.value === "1" ? 1 : 2;

        applyModal.formData.position = auth.value.position;
        applyModal.formData.address = `${applyModal.formData.city}${applyModal.formData.district}${applyModal.formData.address}`;

        if (applyModal.formData.capital_Stock_Amount === "")
          applyModal.formData.capital_Stock_Amount = "0";

        console.log(
          "🚀 ~ file: ApplyVendor.vue ~ line 464 ~ sendApply ~ formData",
          applyModal.formData
        );

        const data = {
          ...applyData.user,
          ...applyModal.formData
        };

        delete data.email;
        delete data.userName;

        switch (userIdentity.value) {
          case "1":
            data.contact_Title = "公司代表人、董監事、經理人";
            data.position = 1;
            break;
          case "2":
            data.contact_Title = "助理與特助";
            data.position = 2;
            break;
          case "3":
            data.contact_Title = "業務負責人";
            data.position = 2;
            break;
          default:
            data.position = 2;
            break;
        }

        if (data) {
          await model?.dispatch("vendor/apply", data).then(async payload => {
            if (payload.status) {
              status.value = await getStatus();

              if (status.value) status.value.isApplying = true;
              console.log(status.value);

              CloudFun.send("info", {
                subject: "已送出申請",
                content: "我們將盡快進行審核"
              });
            } else {
              CloudFun.send("error", {
                subject: "申請失敗",
                content: payload.message
              });
            }
          });
        }
      },
      backTo1: () => {},
      toStep1: () => {
        console.log("toStep1");
        applyModal.step = 1;
      },
      toStep2: () => {
        console.log("toStep2");
        applyModal.step = 2;
      }
    });

    const applyValidationSchema = Yup.object().shape({
      name: Yup.string().required("必要欄位"),
      //name_Eng: Yup.string().required("必要欄位"),
      //address_Eng: Yup.string().required("必要欄位"),
      address: Yup.string().required("必要欄位"),
      city: Yup.string().required("必要欄位"),
      district: Yup.string().required("必要欄位"),
      setup_Date: Yup.string().required("必要欄位")
      // taxID: Yup.string()
      //   .length(8, "統一編號格式錯誤")
      //   .required("必要欄位"),
      //capital_Stock_Amount: Yup.string().required("必要欄位"),
      //paid_In_Capital_Amount: Yup.string().required("必要欄位"),
    });

    // const phoneExp = /^09[0-9]{8}$/;
    const phoneExp = /(\d{2,3}-?|\(\d{2,3}\))\d{3,4}-?\d{4}|09\d{2}(\d{6}|-\d{3}-\d{3})/;
    const contactValidationSchema = Yup.object().shape({
      contact_Title: Yup.string().required("必要欄位"),
      contact_Tel: Yup.string()
        .matches(phoneExp, "業務電話格式錯誤")
        .required("必要欄位"),
      contact_Email: Yup.string()
        .email("業務信箱格式錯誤")
        .required("必要欄位")
    });

    const joinValidationSchema = Yup.object().shape({
      contact_Email: Yup.string()
        .email("Email 格式不正確")
        .required("必要欄位"),
      contact_Title: Yup.string().test(
        "contact_Title",
        "必要欄位",
        (value, schema) => {
          if (userIdentity.value === "9")
            return value != null && value.length > 0;
          return true;
        }
      )
    });

    watch(
      () => props.taxID,
      async (value: any) => {
        console.log("taxID", value);
        applyModal.formData.taxID = value;
        status.value = await getStatus();
        console.log("status.value", status.value);
      }
    );

    const changeCity = (city: any) => {
      applyModal.formData.district = "";
      districts.value = districtList(city);
      applyModal.formData.district = districts.value[0].value;
    };

    return {
      info,
      vendor,
      auth,
      status,
      applyValidationSchema,
      contactValidationSchema,
      applyModal,
      emit,
      city,
      districts,
      changeCity,
      applyData,
      userIdentity,
      joinValidationSchema,
      uploadAction: `${process.env.VUE_APP_BACKEND_URL}/api/v1/Image`
    };
  },
  methods: {
    async uploaderFilter(
      current: VueUploadItem,
      original: VueUploadItem,
      prevent: any
    ) {
      if (!current || !current.name) return prevent();
      if (!/\.(png|gif|jpg|jpeg)$/i.test(current.name)) {
        alert("未支援此種圖片格式");
        return prevent();
      }
    },
    uploadResult(value: any) {
      if (value?.archiveID) {
        this.applyData.user.archiveID = value?.archiveID;
      } else if (value === "delete") this.applyData.user.archiveID = "";
    }
  }
});
</script>
