
/* eslint-disable */

import { ref, reactive, onMounted, provide, computed, watch } from "vue";
import CloudFun, { defineComponent } from "@cloudfun/core";
import ToUser from "@/components/user-modal/Main.vue";
import router from "@/router";
import CompanyEdit from "@/views/company-edit/Main.vue";
import CompanyApply from "@/views/company-apply/Main.vue";
import CfInputBox from "@/cloudfun/components/CfInputBoxHorizontal.vue";
import * as Yup from "yup";
import { Form } from "vee-validate";
import { cityList, districtList } from "@/libs/city";
import CfSelectBox from "@/cloudfun/components/CfSelectBox.vue";
import FileUploader from "@/cloudfun/components/FileUploader.vue";
import { VueUploadItem } from "vue-upload-component";
import CfInputRadioBox from "@/cloudfun/components/CfInputRadioBox.vue";
export default defineComponent({
  components: {
    ToUser,
    CompanyEdit,
    CompanyApply,
    VForm: Form,
    CfInputBox,
    CfSelectBox,
    FileUploader,
    CfInputRadioBox
  },
  props: {
    taxID: String
  },
  setup(props, { emit }) {
    const model = CloudFun.current?.model;
    const info = ref<any>({});
    const vendor = ref<any>({});
    const districts = ref<{ text: string; value: string }[]>([]);
    const city = ref<{ text: string; value: string }[]>([]);
    const applyData = reactive({ user: {} as any });
    const userIdentity = ref("1");
    const getStatus = () => {
      return model
        ?.dispatch("vendor/status")
        .then(response => {
          if (response.status) {
            return response.payload;
          } else {
            CloudFun.send("error", {
              subject: "讀取失敗",
              content: response.message
            });
          }
        })
        .catch(error => {
          CloudFun.send("error", {
            subject: "讀取失敗",
            content: error.message
          });
        });
    };

    const getInfo = () => {
      return model
        ?.dispatch("user/find")
        .then(response => {
          if (response.status) {
            return response.payload;
          } else {
            CloudFun.send("error", {
              subject: "讀取失敗",
              content: response.message
            });
          }
        })
        .catch(error => {
          CloudFun.send("error", {
            subject: "讀取失敗",
            content: error.message
          });
        });
    };

    const getAuth = () => {
      return model
        ?.dispatch("user/auth")
        .then(response => {
          if (response.status) {
            return response.payload;
          } else {
            CloudFun.send("error", {
              subject: "讀取失敗",
              content: response.message
            });
          }
        })
        .catch(error => {
          CloudFun.send("error", {
            subject: "讀取失敗",
            content: error.message
          });
        });
    };

    const auth = ref<any>({});
    const status = ref<any>({});

    onMounted(async () => {
      applyModal.formData.taxID = props.taxID;
      auth.value = await getAuth();
      status.value = await getStatus();
      info.value = await getInfo();
      city.value = cityList();
      console.log(city.value);
    });

    const applyModal = reactive({
      step: 1,
      formData: <any>{
        name_Eng: "",
        paid_In_Capital_Amount: "0",
        capital_Stock_Amount: "",
        address_Eng: "",
        unit: ""
      },
      async sendApply() {
        applyData.user.contact_Tel = " ";
        // applyData.user.position = userIdentity.value === "1" ? 1 : 2;

        applyModal.formData.position = auth.value.position;
        applyModal.formData.address = `${applyModal.formData.city}${applyModal.formData.district}${applyModal.formData.address}`;

        if (applyModal.formData.capital_Stock_Amount === "")
          applyModal.formData.capital_Stock_Amount = "0";

        console.log(
          "🚀 ~ file: ApplyVendor.vue ~ line 464 ~ sendApply ~ formData",
          applyModal.formData
        );

        const data = {
          ...applyData.user,
          ...applyModal.formData
        };

        delete data.email;
        delete data.userName;

        switch (userIdentity.value) {
          case "1":
            data.contact_Title = "公司代表人、董監事、經理人";
            data.position = 1;
            break;
          case "2":
            data.contact_Title = "助理與特助";
            data.position = 2;
            break;
          case "3":
            data.contact_Title = "業務負責人";
            data.position = 2;
            break;
          default:
            data.position = 2;
            break;
        }

        if (data) {
          await model?.dispatch("vendor/apply", data).then(async payload => {
            if (payload.status) {
              status.value = await getStatus();

              if (status.value) status.value.isApplying = true;
              console.log(status.value);

              CloudFun.send("info", {
                subject: "已送出申請",
                content: "我們將盡快進行審核"
              });
            } else {
              CloudFun.send("error", {
                subject: "申請失敗",
                content: payload.message
              });
            }
          });
        }
      },
      backTo1: () => {},
      toStep1: () => {
        console.log("toStep1");
        applyModal.step = 1;
      },
      toStep2: () => {
        console.log("toStep2");
        applyModal.step = 2;
      }
    });

    const applyValidationSchema = Yup.object().shape({
      name: Yup.string().required("必要欄位"),
      //name_Eng: Yup.string().required("必要欄位"),
      //address_Eng: Yup.string().required("必要欄位"),
      address: Yup.string().required("必要欄位"),
      city: Yup.string().required("必要欄位"),
      district: Yup.string().required("必要欄位"),
      setup_Date: Yup.string().required("必要欄位")
      // taxID: Yup.string()
      //   .length(8, "統一編號格式錯誤")
      //   .required("必要欄位"),
      //capital_Stock_Amount: Yup.string().required("必要欄位"),
      //paid_In_Capital_Amount: Yup.string().required("必要欄位"),
    });

    // const phoneExp = /^09[0-9]{8}$/;
    const phoneExp = /(\d{2,3}-?|\(\d{2,3}\))\d{3,4}-?\d{4}|09\d{2}(\d{6}|-\d{3}-\d{3})/;
    const contactValidationSchema = Yup.object().shape({
      contact_Title: Yup.string().required("必要欄位"),
      contact_Tel: Yup.string()
        .matches(phoneExp, "業務電話格式錯誤")
        .required("必要欄位"),
      contact_Email: Yup.string()
        .email("業務信箱格式錯誤")
        .required("必要欄位")
    });

    const joinValidationSchema = Yup.object().shape({
      contact_Email: Yup.string()
        .email("Email 格式不正確")
        .required("必要欄位"),
      contact_Title: Yup.string().test(
        "contact_Title",
        "必要欄位",
        (value, schema) => {
          if (userIdentity.value === "9")
            return value != null && value.length > 0;
          return true;
        }
      )
    });

    watch(
      () => props.taxID,
      async (value: any) => {
        console.log("taxID", value);
        applyModal.formData.taxID = value;
        status.value = await getStatus();
        console.log("status.value", status.value);
      }
    );

    const changeCity = (city: any) => {
      applyModal.formData.district = "";
      districts.value = districtList(city);
      applyModal.formData.district = districts.value[0].value;
    };

    return {
      info,
      vendor,
      auth,
      status,
      applyValidationSchema,
      contactValidationSchema,
      applyModal,
      emit,
      city,
      districts,
      changeCity,
      applyData,
      userIdentity,
      joinValidationSchema,
      uploadAction: `${process.env.VUE_APP_BACKEND_URL}/api/v1/Image`
    };
  },
  methods: {
    async uploaderFilter(
      current: VueUploadItem,
      original: VueUploadItem,
      prevent: any
    ) {
      if (!current || !current.name) return prevent();
      if (!/\.(png|gif|jpg|jpeg)$/i.test(current.name)) {
        alert("未支援此種圖片格式");
        return prevent();
      }
    },
    uploadResult(value: any) {
      if (value?.archiveID) {
        this.applyData.user.archiveID = value?.archiveID;
      } else if (value === "delete") this.applyData.user.archiveID = "";
    }
  }
});
