
/* eslint-disable */

import { ref, reactive, onMounted, provide, computed, watch } from "vue";
import CloudFun, { defineComponent } from "@cloudfun/core";
import CfInputBoxHorizontal from "@/cloudfun/components/CfInputBoxHorizontal.vue";
import CfInputBox from "@/cloudfun/components/CfInputBox.vue";
import InviteVendor from "./form/InviteVendor.vue";
import CreateVendor from "./form/CrateVendor.vue";
import { Form } from "vee-validate";
import * as Yup from "yup";
import { applyVendorStore } from "@/store/apply-vendor";
import { storeToRefs } from "pinia";
export default defineComponent({
  components: {
    CfInputBox,
    CfInputBoxHorizontal,
    VForm: Form,
    InviteVendor,
    CreateVendor
  },
  setup() {
    const applyStore = applyVendorStore();
    const { step, type, applyData } = storeToRefs(applyStore);
    const formData = reactive({ code: "" });
    const aggreeApply = ref(false);
    const model = CloudFun.current?.model;
    const isApplying = ref(false);
    const info = ref({} as any);
    const validationSchema = Yup.object().shape({
      code: Yup.string().required("必要欄位")
    });
    const errorMessage = ref("");

    watch(step, current => {
      console.log("🚀 ~ file: Application.vue ~ line 367 ~ setup ~ step", step);
      if (errorMessage.value) errorMessage.value = "";
      if (formData.code) formData.code = "";
    });

    const loadUserData = async () => {
      const userData = model?.dispatch("user/find");
      return userData;
    };

    const getStatus = async () => {
      const status = model?.dispatch("vendor/status");
      return status;
    };

    const getInfo = () => {
      return model
        ?.dispatch("user/find")
        .then(response => {
          if (response.status) {
            return response.payload;
          } else {
            CloudFun.send("error", {
              subject: "讀取失敗",
              content: response.message
            });
          }
        })
        .catch(error => {
          CloudFun.send("error", {
            subject: "讀取失敗",
            content: error.message
          });
        });
    };

    onMounted(async () => {
      info.value = await getInfo();
      applyStore.reset();
      const status = await getStatus();
      isApplying.value = status.payload.isApplying
        ? status.payload.isApplying
        : false;

      if (!isApplying.value) {
        const data = await loadUserData();
        applyData.value.user.email = data.payload.email;
        applyData.value.user.userName = data.payload.name;
        applyData.value.user.contact_Email = data.payload.email;
      }
    });

    return {
      validationSchema,
      formData,
      errorMessage,
      step,
      aggreeApply,
      type,
      applyData,
      applyStore,
      isApplying,
      info
    };
  },
  methods: {
    back() {
      this.step = this.step > 1 ? this.step - 1 : 1;
    },
    submit(data: any) {
      console.log("submit", data);
      this.errorMessage = "";
      this.$model.dispatch("vendor/invite", data).then(
        response => {
          console.log("invite response", response);
          if (response.status) {
            cash("#success-modal").modal("show");
          } else {
            this.errorMessage = response.message;
          }
        },
        reason => {
          console.log("invite reason", reason);
        }
      );
    },
    goto(type: string) {
      console.log("🚀 ~ file: Application.vue ~ line 339 ~ goto ~ type", type);

      this.type = type;
    },
    gotoDashboard() {
      cash("#success-modal").modal("hide");
      localStorage.setItem("upgrade", this.info.account);
      sessionStorage.clear();
      this.$router.replace("/login");

      // CloudFun.send("reloadAuth", "");
      // this.$router.replace("/dashboard");
    },
    goBack() {
      console.log("goback");
      this.type = "";
    }
  }
});
