
import CloudFun, { defineComponent, ref, reactive } from "@cloudfun/core";
import CfInputBox from "@/cloudfun/components/CfInputBox.vue";
import CfInputRadioBox from "@/cloudfun/components/CfInputRadioBox.vue";
import { Form } from "vee-validate";
import * as Yup from "yup";
import { toRef, toRefs, watch } from "vue";
import { applyVendorStore } from "@/store/apply-vendor";
import ApplyVendor from "./ApplyVendor.vue";
import { storeToRefs } from "pinia";

export default defineComponent({
  components: {
    CfInputBox,
    VForm: Form,
    CfInputRadioBox,
    ApplyVendor
  },
  props: {
    data: {
      type: Object
    },
    defaultStep: {
      type: Number,
      default: 1
    }
  },
  setup(props) {
    const applyStore = applyVendorStore();
    const { step, applyData } = storeToRefs(applyStore);
    const formData = reactive({ taxId: "" });
    const applyPage = ref<boolean>(false);
    const otherEmail = ref("false");
    const validationSchema = Yup.object().shape({
      taxId: Yup.string()
        .min(8, "請輸入正確統一編號")
        .max(8, "請輸入正確統一編號")
        .required("必要欄位")
    });

    const joinValidationSchema = Yup.object().shape({
      contact_Tel: Yup.string().required("必要欄位"),
      contact_Title: Yup.string().required("必要欄位"),
      contact_Email: Yup.string().test(
        "contact_Email",
        "必要欄位",
        (value, schema) => {
          if (otherEmail.value === "true")
            return value != null && value.length > 0;
          return true;
        }
      )
    });

    // watch(
    //   () => props.data,
    //   current => {
    //     companyData.value = current;
    //   }
    // );

    // watch(
    //   () => props.defaultStep,
    //   current => {
    //     console.log(
    //       "🚀 ~ file: QueryVendor.vue ~ line 215 ~ setup ~ current",
    //       current
    //     );

    //     step.value = current;
    //   }
    // );

    const applyBack = () => {
      applyPage.value = false;
    };

    return {
      validationSchema,
      applyData,
      step,
      formData,
      applyPage,
      joinValidationSchema,
      otherEmail,
      applyBack
    };
  },
  methods: {
    submit(data: any) {
      console.log("submit", data);
      this.$model.dispatch("vendor/findByGCIS", data).then(
        response => {
          if (response.payload) {
            console.log(
              "🚀 ~ file: QueryVendor.vue ~ line 253 ~ submit ~ payload",
              response.payload
            );

            this.applyPage = false;
            this.applyData.company = response.payload;
            console.log(
              "🚀 ~ file: QueryVendor.vue ~ line 276 ~ submit ~ company",
              this.applyData.company
            );
          } else {
            // 改為申請供應商
            this.applyPage = true;
            // CloudFun.send("error", {
            //   subject: "找不到資料",
            //   content: "請檢查輸入統編是否正確"
            // });
          }
        },
        reason => {
          CloudFun.send("error", {
            subject: "讀取失敗",
            content: reason
          });
        }
      );
    }
  }
});
