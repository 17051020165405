/* 邀請成為供應商 */
import { defineStore } from "pinia";
export const applyVendorStore = defineStore("applyVendor", {
  state: () => ({
    step: 1,
    type: "",
    email: "",
    applyData: {
      user: {
        contact_Email: "",
        contact_Tel: "",
        contact_Title: "",
        position: 0,
        userName: "",
        email: "",
        archiveID: "",
        capital_Stock_Amount: "",
        paid_In_Capital_Amount: ""
      } as any,
      company: {} as any
    }
  }),
  actions: {
    reset() {
      this.type = "";
      this.applyData.user = {
        contact_Email: "",
        contact_Tel: "",
        contact_Title: "",
        position: 0,
        email: "",
        userName: "",
        archiveID: "",
        capital_Stock_Amount: "",
        paid_In_Capital_Amount: ""
      };
      this.applyData.company = {};
      this.step = 1;
    }
  }
});
