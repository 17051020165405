
import CloudFun, { defineComponent, ref, reactive } from "@cloudfun/core";
import { computed, watch } from "vue";
import CfInputBoxHorizontal from "@/cloudfun/components/CfInputBoxHorizontal.vue";
import CfInputBox from "@/cloudfun/components/CfInputBox.vue";
import CfInputRadioBox from "@/cloudfun/components/CfInputRadioBox.vue";
import { Form } from "vee-validate";
import * as Yup from "yup";
import QueryVendor from "./QueryVendor.vue";
import { applyVendorStore } from "@/store/apply-vendor";
import { storeToRefs } from "pinia";
export default defineComponent({
  components: {
    CfInputBoxHorizontal,
    VForm: Form,
    CfInputBox,
    CfInputRadioBox,
    QueryVendor
  },
  setup() {
    const formData = reactive({ code: "" });
    const applyStore = applyVendorStore();
    const { step, applyData } = storeToRefs(applyStore);
    const errorMessage = ref("");
    const otherEmail = ref("false");

    watch(step, current => {
      if (errorMessage.value) errorMessage.value = "";
      if (formData.code) formData.code = "";
    });

    const validationSchema = Yup.object().shape({
      code: Yup.string().required("必要欄位")
    });

    const joinValidationSchema = Yup.object().shape({
      contact_Tel: Yup.string().required("必要欄位"),
      contact_Title: Yup.string().required("必要欄位"),
      contact_Email: Yup.string().test("contact_Email", "必要欄位", value => {
        if (otherEmail.value === "true")
          return value != null && value.length > 0;
        return true;
      })
    });

    return {
      formData,
      step,
      errorMessage,
      validationSchema,
      applyData,
      joinValidationSchema,
      otherEmail
    };
  },
  methods: {
    back() {
      this.step = this.step > 1 ? this.step - 1 : 1;
    },
    submit(data: any) {
      console.log("submit", data);
      this.errorMessage = "";
      this.$model.dispatch("vendor/invite", data).then(
        response => {
          console.log("invite response", response);
          if (response.status) {
            cash("#success-modal").modal("show");
          } else {
            this.errorMessage = response.message;
          }
        },
        reason => {
          console.log("invite reason", reason);
        }
      );
    },
    callback(data: any) {
      this.applyData.company = data;
      this.step += 1;
      debugger;
      console.log(
        "🚀 ~ file: InviteVendor.vue ~ line 164 ~ callBack ~  this.applyData.company",
        this.applyData.company
      );
    },
    applySubmit() {
      if (
        this.otherEmail === "false" &&
        this.applyData.user.contact_Email !== this.applyData.user.email
      ) {
        this.applyData.user.contact_Email = this.applyData.user.email;
      }

      this.applyData.company.unit = "";

      // if (this.applyData.user.capital_Stock_Amount === "") {
      //   this.applyData.user.capital_Stock_Amount = this.applyData.company.user.paid_In_Capital_Amount;
      // }

      // this.applyData.user.position = 0;
      const data = {
        ...this.applyData.user,
        ...this.applyData.company
      } as any;

      delete data.email;
      console.log(
        "🚀 ~ file: InviteVendor.vue ~ line 386 ~ applySubmit ~ data",
        data
      );

      if (data) {
        this.$model.dispatch("vendor/apply", data).then(
          response => {
            if (response.status) {
              CloudFun.send("success", {
                subject: "申請送出",
                content: "成功"
              });
              this.step = 3;
            } else {
              CloudFun.send("error", {
                subject: "申請失敗",
                content: response.message
              });
            }
          },
          reason => {
            CloudFun.send("error", {
              subject: "申請失敗",
              content: reason
            });
          }
        );
      }
    }
  }
});
