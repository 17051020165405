<template>
  <!-- 加入供應商 -->
  <div id="joinData" class="intro-y box p-10 mt-10" v-if="step === 0">
    <div class="font-bold text-2xl xl:text-3xl mt-5 mb-10">加入供應商</div>
    <div class="lg:grid grid-cols-2 ">
      <div
        class="px-5 2xl:px-10 mb-20 pb-10 border-b lg:border-b-0 lg:border-r border-gray-600"
      >
        <div class="mb-12 text-base text-gray-600">
          本平台提供您申請供應商身份，享有以下服務：
          <ul class=" list-decimal pl-5 mt-5 mb-10">
            <li class="mb-2">建立公司完整資料，與平台會員交流。</li>
            <li class="mb-2">申請成為供應商成員，協助公司管理產品。</li>
            <li class="mb-2">上架公司產品資訊。</li>
            <li class="mb-2">
              於軟協數位展覽入選展出公司產品與公司介紹。開拓公司商機。
            </li>
          </ul>
        </div>
        <div class=" flex">
          <div class="">
            <a
              href="#"
              @click="step = 2"
              class="button text-lg px-10 block text-white bg-theme-1 shadow-md"
            >
              申請加入供應商
            </a>
          </div>
        </div>
      </div>
      <VForm
        :validation-schema="validationSchema"
        :initial-values="formData"
        @submit="submit"
      >
        <div class="px-5 2xl:px-10 mb-20 pb-10">
          <div class="mb-10 text-base text-gray-600">
            如果您已經收到公司寄給您的邀請碼，<br />
            請填入邀請碼以完成供應商身份轉換：
          </div>
          <div class="mb-5 w-full sm:w-96">
            <CfInputBoxHorizontal
              label=""
              name="code"
              placeholder="輸入邀請碼"
              type="text"
              :isRequired="true"
              :modelValue="formData.code"
              @update:modelValue="
                val => {
                  formData.code = val;
                }
              "
            ></CfInputBoxHorizontal>
            <div class="text-base text-red-500 justify-center">
              {{ errorMessage }}
            </div>
            <!-- <input
            type="text"
            class="intro-x input input--lg border border-gray-300 w-full mt-2 mb-1"
            placeholder="輸入邀請碼"
            />  -->
          </div>
          <div class=" flex">
            <div class="">
              <button
                type="submit"
                class="button items-center button--lg w-full lg:w-32 text-white bg-theme-1 align-top lg:mb-0 mt-6 ml-3"
              >
                驗證邀請碼
              </button>
              <!-- <a
                href="javascript:;"
                @click="joinEnterStep()"
                class="button text-lg px-10 block text-white bg-theme-1 shadow-md"
              >
                驗證邀請碼
              </a> -->
            </div>
          </div>
        </div></VForm
      >
    </div>
    <div class=" flex justify-end items-center">
      <div class=" flex items-center">
        <a
          href="javascript:;"
          @click="() => $emit('back')"
          class="button text-lg px-10 block text-theme-1 bg-transparent mr-2"
        >
          回上一步
        </a>
      </div>
    </div>
  </div>

  <div>
    <query-vendor
      v-if="step === 1"
      :data="applyData.company"
      @back="() => $emit('back')"
      @return="callback"
    ></query-vendor>
  </div>

  <!-- step3確認資料 -->
  <div v-if="step === 2" class="intro-y box p-10 mt-10">
    <VForm
      ref="joinForm"
      :validation-schema="joinValidationSchema"
      :initial-values="applyData.user"
      @submit="applySubmit"
    >
      <div class="font-bold text-2xl xl:text-3xl my-5">申請加入</div>
      <div class="mb-10 text-base text-gray-600">
        為了保障您使用本平台各功能的權益，請確實填寫以下資料，以利我方審核。
      </div>

      <div class="mb-20">
        <div class="lg:grid grid-cols-2 gap-10">
          <div class="">
            <div class=" mb-5">
              <label class=" font-medium text-lg"
                >職稱<span class=" text-red-400">＊</span></label
              >
              <CfInputBox
                name="contact_Title"
                placeholder="職稱"
                :modelValue="applyData.user.contact_Title"
                @update:modelValue="
                  val => {
                    applyData.user.contact_Title = val;
                  }
                "
                type="text"
              ></CfInputBox>
            </div>
            <div class=" mb-5">
              <label class=" font-medium text-lg"
                >公司業務電話<span class=" text-red-400">＊</span></label
              >
              <div class=" flex items-center">
                <CfInputBox
                  name="contact_Tel"
                  placeholder="公司業務電話"
                  type="text"
                  :modelValue="applyData.user.contact_Tel"
                  @update:modelValue="
                    val => {
                      applyData.user.contact_Tel = val;
                    }
                  "
                ></CfInputBox>
                <!-- <div class="mx-3 text-lg">#</div>
                  <div class=" w-2/5">
                    <CfInputBox
                      name="phone"
                      placeholder="分機"
                      type="text"
                    ></CfInputBox>
                  </div> -->
              </div>
            </div>
          </div>
          <div class=" mb-5">
            <div class="">
              <label class=" font-medium text-lg"
                >預設業務信箱<span class=" text-red-400">＊</span></label
              >

              <!-- <label class="input">{{ applyData.user.email }}</label> -->
              <CfInputBox
                name="email"
                placeholder="業務信箱"
                type="email"
                v-model="applyData.user.email"
                :disabled="true"
              ></CfInputBox>
            </div>
            <div class="text-base mb-3">
              <CfInputRadioBox
                id="default"
                name="email"
                defaultValue="false"
                :modelValue="otherEmail"
                @update:modelValue="
                  val => {
                    otherEmail = val;
                  }
                "
              ></CfInputRadioBox>

              <!-- <input
                  type="radio"
                  name="email"
                  id="default"
                  value="false"
                  v-model="otherEmail"
                  class="input border mr-2"
                  checked
                /> -->
              <label class="cursor-pointer select-none" for="default">
                設為主要聯絡信箱
              </label>
            </div>
            <div class="text-base mb-3">
              <CfInputRadioBox
                id="customize"
                name="otherEmail"
                defaultValue="true"
                :modelValue="otherEmail"
                @update:modelValue="
                  val => {
                    otherEmail = val;
                  }
                "
              ></CfInputRadioBox>

              <!-- <input
                  type="radio"
                  name="email"
                  id="customize"
                  value="true"
                  v-model="otherEmail"
                  class="input border mr-2"
                /> -->
              <label class="cursor-pointer select-none" for="customize">
                新增其他信箱作為主要聯絡信箱
              </label>
              <div id="customizeInput" v-if="otherEmail === 'true'">
                <CfInputBox
                  name="contact_Email"
                  placeholder="輸入其他信箱"
                  type="email"
                  :modelValue="applyData.user.contact_Email"
                  @update:modelValue="
                    val => {
                      applyData.user.contact_Email = val;
                    }
                  "
                ></CfInputBox>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class=" flex justify-end items-center">
        <div class=" flex items-center">
          <a
            href="javascript:;"
            @click="back"
            class="button text-lg px-10 block text-theme-1 bg-transparent mr-2"
          >
            上一步
          </a>

          <button
            type="submit"
            class="button items-center button--lg w-full lg:w-32 text-white bg-theme-1 align-top lg:mb-0 ml-3"
          >
            送出申請
          </button>
          <!-- <a
              href="javascript:;"
              @click="enterCompanyData()"
              class="button text-lg px-10 block text-white shadow-md bg-theme-1 mr-2"
            >
              
            </a> -->
        </div>
      </div>
    </VForm>
  </div>
  <!-- step4 資料送出-->
  <div v-if="step === 3" class="intro-y box p-10 mt-10 h-96 content-center">
    <div class="item-center w-full h-full text-center">
      <div class="text-2xl">您的申請已完成</div>
      <div class="text-lg">平台正在為您開通功能</div>
    </div>
  </div>
  <!--  -->
</template>

<script lang="ts">
import CloudFun, { defineComponent, ref, reactive } from "@cloudfun/core";
import { computed, watch } from "vue";
import CfInputBoxHorizontal from "@/cloudfun/components/CfInputBoxHorizontal.vue";
import CfInputBox from "@/cloudfun/components/CfInputBox.vue";
import CfInputRadioBox from "@/cloudfun/components/CfInputRadioBox.vue";
import { Form } from "vee-validate";
import * as Yup from "yup";
import QueryVendor from "./QueryVendor.vue";
import { applyVendorStore } from "@/store/apply-vendor";
import { storeToRefs } from "pinia";
export default defineComponent({
  components: {
    CfInputBoxHorizontal,
    VForm: Form,
    CfInputBox,
    CfInputRadioBox,
    QueryVendor
  },
  setup() {
    const formData = reactive({ code: "" });
    const applyStore = applyVendorStore();
    const { step, applyData } = storeToRefs(applyStore);
    const errorMessage = ref("");
    const otherEmail = ref("false");

    watch(step, current => {
      if (errorMessage.value) errorMessage.value = "";
      if (formData.code) formData.code = "";
    });

    const validationSchema = Yup.object().shape({
      code: Yup.string().required("必要欄位")
    });

    const joinValidationSchema = Yup.object().shape({
      contact_Tel: Yup.string().required("必要欄位"),
      contact_Title: Yup.string().required("必要欄位"),
      contact_Email: Yup.string().test("contact_Email", "必要欄位", value => {
        if (otherEmail.value === "true")
          return value != null && value.length > 0;
        return true;
      })
    });

    return {
      formData,
      step,
      errorMessage,
      validationSchema,
      applyData,
      joinValidationSchema,
      otherEmail
    };
  },
  methods: {
    back() {
      this.step = this.step > 1 ? this.step - 1 : 1;
    },
    submit(data: any) {
      console.log("submit", data);
      this.errorMessage = "";
      this.$model.dispatch("vendor/invite", data).then(
        response => {
          console.log("invite response", response);
          if (response.status) {
            cash("#success-modal").modal("show");
          } else {
            this.errorMessage = response.message;
          }
        },
        reason => {
          console.log("invite reason", reason);
        }
      );
    },
    callback(data: any) {
      this.applyData.company = data;
      this.step += 1;
      debugger;
      console.log(
        "🚀 ~ file: InviteVendor.vue ~ line 164 ~ callBack ~  this.applyData.company",
        this.applyData.company
      );
    },
    applySubmit() {
      if (
        this.otherEmail === "false" &&
        this.applyData.user.contact_Email !== this.applyData.user.email
      ) {
        this.applyData.user.contact_Email = this.applyData.user.email;
      }

      this.applyData.company.unit = "";

      // if (this.applyData.user.capital_Stock_Amount === "") {
      //   this.applyData.user.capital_Stock_Amount = this.applyData.company.user.paid_In_Capital_Amount;
      // }

      // this.applyData.user.position = 0;
      const data = {
        ...this.applyData.user,
        ...this.applyData.company
      } as any;

      delete data.email;
      console.log(
        "🚀 ~ file: InviteVendor.vue ~ line 386 ~ applySubmit ~ data",
        data
      );

      if (data) {
        this.$model.dispatch("vendor/apply", data).then(
          response => {
            if (response.status) {
              CloudFun.send("success", {
                subject: "申請送出",
                content: "成功"
              });
              this.step = 3;
            } else {
              CloudFun.send("error", {
                subject: "申請失敗",
                content: response.message
              });
            }
          },
          reason => {
            CloudFun.send("error", {
              subject: "申請失敗",
              content: reason
            });
          }
        );
      }
    }
  }
});
</script>

<style scoped></style>
