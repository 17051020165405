<template>
  <div>
    <!-- BEGIN: HTML Table Data -->
    <!-- step1輸入公司統一編號 -->
    <div
      v-if="
        (applyData.company?.taxID == null || applyData.company?.taxID == '') &&
          applyPage == false
      "
      class="intro-y box p-10 mt-10"
    >
      <div class="font-bold text-2xl xl:text-3xl mt-5 mb-10">
        建立供應商企業資料
      </div>
      <div class="mb-20">
        <VForm
          :validation-schema="validationSchema"
          :initial-values="formData"
          @submit="submit"
        >
          <div class=" flex items-center">
            <div class="w-full sm:w-96">
              <label class="font-medium text-lg"
                >公司統編<span class=" text-red-400">＊</span></label
              >
              <CfInputBox
                name="taxId"
                placeholder="公司統編"
                maxLength="8"
                :modelValue="formData.taxId"
                @update:modelValue="
                  val => {
                    formData.taxId = val;
                  }
                "
                :showMessage="true"
                type="text"
              ></CfInputBox>
            </div>
            <div class=" ">
              <button
                type="submit"
                class="button items-center button--lg w-full lg:w-32 text-white bg-theme-1 align-top lg:mb-0 mt-6 ml-3"
              >
                查詢
              </button>
            </div>
          </div>
        </VForm>
      </div>
      <div class=" flex justify-end items-center">
        <div class=" flex items-center">
          <a
            href="#"
            @click="() => $emit('back')"
            class="button text-lg px-10 block text-theme-1 bg-transparent mr-2"
          >
            回上一步
          </a>
        </div>
      </div>
    </div>

    <!-- step2確認資料 -->
    <div
      v-if="
        applyData.company?.taxID != null && applyData.company.taxID.length === 8
      "
      class=" intro-y box p-10 mt-10"
    >
      <div class="font-bold text-2xl xl:text-3xl mt-5 mb-10">
        建立供應商企業資料
      </div>
      <div class="mb-10 border-b border-theme-5">
        <div class=" md:flex items-end mb-10">
          <div class="w-full sm:w-96">
            <label class=" font-medium text-lg">公司統編</label>
            <input
              type="text"
              class="intro-x input input--lg border border-gray-300 w-full mt-2 mb-1"
              :value="applyData.company?.taxID"
              disabled
            />

            <!-- <div class=" text-gray-600 text-base">
              {{ applyData.company.taxID }}
            </div> -->
          </div>
          <div class="w-full text-gray-600 ml-2 mb-4">
            <!-- 您已經是軟協會員，已為您確認相關資料，請送出申請。 -->
            <!-- 您不是軟協會員，請送出申請，我們將核對您的相關資料。 -->
          </div>
        </div>
      </div>

      <div class="mb-20">
        <div class="lg:grid grid-cols-2 gap-10">
          <div class=" col-span-1 mb-5">
            <label class=" font-medium text-lg"
              >公司名稱<span class=" text-red-400">＊</span></label
            >
            <div class=" text-gray-600 text-base">
              {{ applyData.company?.name }}
            </div>
          </div>
          <div class=" col-span-1 mb-5">
            <label class=" font-medium text-lg">公司英文名稱</label>
            <div class=" text-gray-600 text-base">
              {{ applyData.company?.name_Eng }}
            </div>
          </div>
          <div class=" col-span-1 mb-5">
            <label class=" font-medium text-lg"
              >核准設立日期<span class=" text-red-400">＊</span></label
            >
            <div class=" text-gray-600 text-base">
              {{ applyData.company?.setup_Date }}
            </div>
          </div>
          <div class=" col-span-1 mb-5">
            <label class=" font-medium text-lg"
              >實收資本額（元）<span class=" text-red-400">＊</span></label
            >
            <div class=" text-gray-600 text-base">
              {{ applyData.company?.capital_Stock_Amount }}
            </div>
          </div>
          <div class=" col-span-2 mb-5">
            <label class=" font-medium text-lg"
              >公司登記地址<span class=" text-red-400">＊</span></label
            >
            <div class=" text-gray-600 text-base">
              {{ applyData.company?.address }}
            </div>
          </div>
          <div class=" col-span-2 mb-5">
            <label class=" font-medium text-lg">公司登記英文地址</label>
            <div class=" text-gray-600 text-base">
              {{ applyData.company?.address_Eng }}
            </div>
          </div>
        </div>
      </div>

      <div class=" flex justify-end items-center">
        <div class=" flex items-center">
          <a
            href="javascript:;"
            @click="
              () => {
                applyData.company = {};
              }
            "
            class="button text-lg px-10 block text-theme-1 bg-transparent mr-2"
          >
            回上一步
          </a>
          <a
            href="javascript:;"
            @click="step += 1"
            class="button text-lg px-10 block text-white shadow-md bg-theme-1 mr-2"
          >
            下一步
          </a>
        </div>
      </div>
    </div>
  </div>

  <div v-if="applyPage == true">
    <ApplyVendor :taxID="formData.taxId" @applyBack="applyBack" />
  </div>
</template>

<script lang="ts">
import CloudFun, { defineComponent, ref, reactive } from "@cloudfun/core";
import CfInputBox from "@/cloudfun/components/CfInputBox.vue";
import CfInputRadioBox from "@/cloudfun/components/CfInputRadioBox.vue";
import { Form } from "vee-validate";
import * as Yup from "yup";
import { toRef, toRefs, watch } from "vue";
import { applyVendorStore } from "@/store/apply-vendor";
import ApplyVendor from "./ApplyVendor.vue";
import { storeToRefs } from "pinia";

export default defineComponent({
  components: {
    CfInputBox,
    VForm: Form,
    CfInputRadioBox,
    ApplyVendor
  },
  props: {
    data: {
      type: Object
    },
    defaultStep: {
      type: Number,
      default: 1
    }
  },
  setup(props) {
    const applyStore = applyVendorStore();
    const { step, applyData } = storeToRefs(applyStore);
    const formData = reactive({ taxId: "" });
    const applyPage = ref<boolean>(false);
    const otherEmail = ref("false");
    const validationSchema = Yup.object().shape({
      taxId: Yup.string()
        .min(8, "請輸入正確統一編號")
        .max(8, "請輸入正確統一編號")
        .required("必要欄位")
    });

    const joinValidationSchema = Yup.object().shape({
      contact_Tel: Yup.string().required("必要欄位"),
      contact_Title: Yup.string().required("必要欄位"),
      contact_Email: Yup.string().test(
        "contact_Email",
        "必要欄位",
        (value, schema) => {
          if (otherEmail.value === "true")
            return value != null && value.length > 0;
          return true;
        }
      )
    });

    // watch(
    //   () => props.data,
    //   current => {
    //     companyData.value = current;
    //   }
    // );

    // watch(
    //   () => props.defaultStep,
    //   current => {
    //     console.log(
    //       "🚀 ~ file: QueryVendor.vue ~ line 215 ~ setup ~ current",
    //       current
    //     );

    //     step.value = current;
    //   }
    // );

    const applyBack = () => {
      applyPage.value = false;
    };

    return {
      validationSchema,
      applyData,
      step,
      formData,
      applyPage,
      joinValidationSchema,
      otherEmail,
      applyBack
    };
  },
  methods: {
    submit(data: any) {
      console.log("submit", data);
      this.$model.dispatch("vendor/findByGCIS", data).then(
        response => {
          if (response.payload) {
            console.log(
              "🚀 ~ file: QueryVendor.vue ~ line 253 ~ submit ~ payload",
              response.payload
            );

            this.applyPage = false;
            this.applyData.company = response.payload;
            console.log(
              "🚀 ~ file: QueryVendor.vue ~ line 276 ~ submit ~ company",
              this.applyData.company
            );
          } else {
            // 改為申請供應商
            this.applyPage = true;
            // CloudFun.send("error", {
            //   subject: "找不到資料",
            //   content: "請檢查輸入統編是否正確"
            // });
          }
        },
        reason => {
          CloudFun.send("error", {
            subject: "讀取失敗",
            content: reason
          });
        }
      );
    }
  }
});
</script>

<style scoped></style>
